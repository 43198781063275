import React from 'react'
import { useLocation } from "@reach/router";
import useDataLayer from '../../hooks/useDataLayer'

import "./ContactForm.scss"
import { Link } from "gatsby"
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import axios from 'axios'

class ContactForm extends React.Component {

    constructor(props) {
        super(props)
        
        const userForm = {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            comments: '',
        }
        const { search } = props.location
        const usp = new URLSearchParams(search);
        for(const [key, value] of usp.entries()) {
            userForm.hasOwnProperty(key) && (userForm[key] = value);
        }

        this.state = {
            ...userForm,
            loading: false,
            call: false,
            text: false,
            checkEmail: false,
            id: this.props.id,
            pageSource: this.props.pageSource,
            pageURL: this.props.pageURL
        }
        console.log(this.state)

        this.handleCheckedInput = this.handleCheckedInput.bind(this)
        this.handleTextInput = this.handleTextInput.bind(this)

        this.response = React.createRef()

        this.contactSettings = props.data;
        this.dealer = props.dealer;
        this.lead_url = props.lead_url;
    }

    handleCheckedInput(e, data) {
        this.setState({
            [data.name]: data.checked
        })
    }

    handleTextInput(name, value) {
        if (document.getElementById(name).classList.contains('contact-required'))
            document.getElementById(name).classList.remove('contact-required')
        this.setState({
            [name]: value
        })
    }

    phoneMask(e) {
        var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
    }

    checkValid(data) {

        return new Promise((resolve, reject) => {
            try {
                let required = ['firstName', 'lastName', 'email', 'phoneNumber']
                let found = []

                required.forEach(req => {
                    if (data[req] === '') {
                        this.response.current.innerText = 'Please fill out all required form inputs.'
                        this.response.current.classList = 'contact-failure'

                        document.getElementById(req).classList.add('contact-required')

                        found.push(req)
                    }
                })

                resolve(found)
            }

            catch (err) {
                reject(err)
            }
        })
    }

    async submitForm(e) {
        this.setState({
            loading: true
        })

        e.preventDefault()
        

        let data = {}
        data.call = this.state.call
        data.text = this.state.text
        data.checkEmail = this.state.checkEmail
        data.firstName = this.state.firstName
        data.lastName = this.state.lastName
        data.email = this.state.email
        data.phoneNumber = this.state.phoneNumber
        data.comments = this.state.comments
        data.siteId = this.props.site_id
        data.pageSource = this.state.pageSource
        data.pageURL = this.state.pageURL

        if (this.props.car) {
            data.vehicle = this.props.car
            data.vehicle.IsNew = true
        }

        let missing = await this.checkValid(data)

        if (missing.length > 0) {
            this.setState({
                loading: false
            })

            return
        }

        axios.post(this.props.lead_url, JSON.stringify(data)) // this needs an actual backend link
            .then((res) => {
                this.props.pushToDataLayer("form-submission", res)
                this.props.pushToDataLayer("contact", res)
                this.response.current.innerText = 'Success! The message has been sent!'
                this.response.current.classList = 'contact-success'

                this.setState({
                    loading: false
                })
            })
            .catch(err => {
                console.log(err)

                this.setState({
                    loading: false
                })

                this.response.current.innerText = 'Uh oh! Something went wrong, please try again!'
                this.response.current.classList = 'contact-failure'
            })
    }



    render() {
        return (
            <div className="contact-root">
                <div className="contact-header">
                    <h1 className="contact-title">{this.contactSettings.title}</h1>
                    <p className="contact-instructions">{this.contactSettings.text}</p>
                    <Link className="contact-link" to={this.contactSettings.link}>{this.contactSettings.linkText}</Link>
                </div>

                <div className="contact-formCont">
                    <div className="contact-inputCont">
                        <label for="firstName">First Name *</label>
                        <input className="contact-input" id="firstName" name="firstName" placeholder="" 
                            value={this.state.firstName} onChange={(e) => this.handleTextInput(e.target.name, e.target.value)} />
                    </div>
                    <div className="contact-inputCont">
                        <label for="lastName">Last Name *</label>
                        <input className="contact-input" id="lastName" name="lastName" placeholder="" 
                            value={this.state.lastName} onChange={(e) => this.handleTextInput(e.target.name, e.target.value)} />
                    </div>
                    <div className="contact-inputCont contact-phone">
                        <label for="phoneNumber">Phone *</label>
                        <input className="contact-input" type="tel" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" 
                            id="phoneNumber" name="phoneNumber" placeholder="(555) 555-5555"
                            onChange={(e) => this.handleTextInput(e.target.name, e.target.value)}
                            onInput={(e) => this.phoneMask(e)} />
                    </div>
                    <div className="contact-inputCont">
                        <label for="email">Email *</label>
                        <input className="contact-input" type="email" id="email" name="email" placeholder="" 
                            value={this.state.email} onChange={(e) => this.handleTextInput(e.target.name, e.target.value)} />
                    </div>
                    <div className="contact-inputCont contact-comment">
                        <label for="comments">Comment</label>
                        <textarea className="contact-input" id="comments" name="comments" placeholder="" 
                            value={this.state.comments} onChange={(e) => this.handleTextInput(e.target.name, e.target.value)} />
                    </div>
                    <div className="contact-inputCont contact-border">
                        <p className="contact-disclaimer">{this.contactSettings.disclaimer}</p>
                        <button className="contact-button"
                            onClick={(e) => this.submitForm(e)}>
                            {this.state.loading === false ? this.contactSettings.buttonText : <Loader type="ThreeDots" color="white" height={12} width={12} />}
                        </button>
                    </div>
                    <div className="contact-response" ref={this.response}></div>
                    <div className="contact-disclaimer" dangerouslySetInnerHTML={{ __html: this.props.disclaimer }}></div>
                </div>
            </div>
        )
    }
}

function withRouterHOC (Component) {
    return (props) => {
        const pushToDataLayer = useDataLayer()
        const location = useLocation();
        return <Component pushToDataLayer={pushToDataLayer} location={location} {...props} />;
    };
};

export default withRouterHOC(ContactForm)

