import React from "react"
import Layout from '../components/layout'
import ContactForm from '../components/contact/ContactForm'
import ServiceForm from '../components/contact/ServiceForm'
import HTML from "../components/HTML"
import ImageParagraph from "../components/image/ImageParagraphComponent"
import AddtlButtons from "../components/additionalButtons/AddtlButtonsComponent"
import FinanceWidget from "../components/finance/FinanceWidget"
import FinanceForm from "../components/financeForm/FinanceForm"
import TradeInForm from "../components/tradeInForm/TradeInForm"

function Contact(props) {
  const { pageContext } = props
  const { pageContent, theme,dealerInfo,inventorymanagement,lead_url,site_id } = pageContext
  const dealerHours = dealerInfo
  const dealerLoc = dealerInfo
  const document = typeof window !== 'undefined' && window.document

  let components = [
    <HTML key={"HTML"} data={pageContent.HTML} />,
    <ImageParagraph  data={pageContent.ImageParagraphComponent}></ImageParagraph>,
    <ContactForm data={pageContent.ContactForm} dealer={dealerInfo} site_id={pageContext.site_id} lead_url={pageContext.lead_url} car={null} pageSource='Contact' pageURL={document.URL}></ContactForm>,
    <AddtlButtons disclaimerContact={inventorymanagement.disclaimerContact} data={pageContent.AddtlButtonsComponent} leadURL={lead_url} site_id={site_id} >
    </AddtlButtons>,
    <FinanceWidget data={pageContent.FinanceWidget} leadURL={pageContext.lead_url} pageSource='FinanceApp' pageURL={document.URL}  site_id={pageContext.site_id} extraData={""}/>,
    <FinanceForm data={pageContent.FinanceForm} leadURL={pageContext.lead_url} site_id={pageContext.site_id} pageSource='FinanceForm' pageURL={document.URL} />,
    <TradeInForm data={pageContent.TradeInForm} leadURL={pageContext.lead_url} site_id={pageContext.site_id} pageSource='TradeInForm' pageURL={document.URL} />,
    <ServiceForm data={pageContent.ServiceForm} leadURL={pageContext.lead_url} pageSource='Service' pageURL={document.URL}  site_id={pageContext.site_id} extraData={""}></ServiceForm>,
  ]

  let sortedComponents = components.sort((a, b) =>
     parseFloat(a.props.data.order) - parseFloat(b.props.data.order)
  ) // sorts the components based on json data
  // filters out the components that are not visible
  let visibleComponents = sortedComponents.filter(component => {
    return component.props.data.visible === true
  })

  return (
    <Layout seo={pageContent.SEO}>
      { visibleComponents}
    </Layout>
  )
}

export default Contact
