import React, { useState } from 'react'
import Loader from "react-loader-spinner";
import axios from 'axios'
import { Link } from "gatsby"

import useDataLayer from '../../hooks/useDataLayer';
import Datepicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import moment from 'moment';

import "./ContactForm.scss"
import grid from "../../scss/flexboxgrid.module.scss"
import view from "../modal/content/form.module.scss"

function ServiceForm(props) {
  const { leadURL, extraData } = props
  const VehicleInfo = null
  const Pricing = null //= extraData
  const serviceSettings = props.data;

  const localStorage = typeof window !== 'undefined' && window.localStorage
  const pushToDataLayer = useDataLayer()
  
  const [fields, setFields] = useState({
    firstName: null, lastName: null, email: null, phoneNumber: null, comments: null,
    vehicleYear: null, vehicleMake: null, vehicleModel: null, vehicleTrim: null,
    vehicleMiles: null, serviceDate: null, contactType: null,
    siteId: props.site_id, pageSource: props.pageSource, pageURL: props.pageURL
  })
  const [error, setError] = useState({
    firstName: false, lastName: false, email: false, phoneNumber: false, 
    serviceDate: false, comments: false
  })
  const [statusMsg, setStatusMsg] = useState("")
  const [loading, isLoading] = useState(false)

  const handleTextInput = (name, value) => {
    setStatusMsg("")
    setFields({ ...fields, ...{ [name]: value } })
  }

 let vehicle = {}
 /* if(VehicleInfo != null && Pricing != null) {
    vehicle = {
      Year: VehicleInfo.Year != null ? VehicleInfo.Year : '',
      Make: VehicleInfo.Make != null ? VehicleInfo.Make : '',
      Model: VehicleInfo.Model != null ? VehicleInfo.Model : '',
      Trim: VehicleInfo.Trim != null ? VehicleInfo.Trim : '',
      List: Pricing.List != null ? Pricing.List : '',
      ExtraPrice1: Pricing.ExtraPrice1 != null ? Pricing.ExtraPrice1 : ''

    }
}*/

    const [pickerDate, setPickerDate] = useState(new Date());

  const phoneMask = (e) => {
    var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '')
  }

  const [isOpen, toggleOpen] = useState([false, false, false,false,false,false,false])

  const toggleMenu = (idx) => {
    const _isOpen = isOpen.map((val, jdx) => {
      return idx == jdx ? !val : false
    })
    if(idx === 3){
      document.querySelector('.more-filters').classList.toggle('more-filters_visible')
    }
    toggleOpen(_isOpen)
  }

  const hideMenus = () => {
    const _isOpen = isOpen.map(() => (false))
    toggleOpen(_isOpen)
    //document.querySelector('.more-filters').classList.remove('more-filters_visible')
  }

  function getStorageItem(item) {
    return (typeof localStorage.getItem !== 'undefined' ? localStorage.getItem(item) : "[]")
  }

  function setStorageItem(item, value) {
    typeof localStorage.setItem !== 'undefined'
      ? localStorage.setItem(item, value)
      : console.log('no localStorage')
  }

  const sendRequest = () => {

    vehicle = {
      VIN: '',
      StockNumber: '',
      Year: fields.vehicleYear != null ? fields.vehicleYear : '',
      Make: fields.vehicleMake != null ? fields.vehicleMake : '',
      Model: fields.vehicleModel != null ? fields.vehicleModel : '',
      Trim: fields.vehicleTrim != null ? fields.vehicleTrim : '',
      Mileage: fields.vehicleMiles != null ? fields.vehicleMiles : '',
      List: '',
      ExtraPrice1: ''
    }

    const request = { ...fields, vehicle }

    axios.post(leadURL, JSON.stringify(request)) // this needs an actual backend link
      .then((res) => {
        pushToDataLayer("form-submission", res)
        pushToDataLayer("service", res)
        setStatusMsg("Success! The message has been sent!")
        isLoading(false)
      })
      .catch(err => {
        console.log(err)
        setStatusMsg("Uh oh! Something went wrong, please try again!")
        isLoading(false)
      })
      
  }

  const submitForm = async (e) => {
    e.preventDefault()
    isLoading(true)

    fields.serviceDate = moment(pickerDate).format("MM/DD/YYYY");

    const empties = ['firstName', 'lastName', 'email', 'phoneNumber', 'serviceDate'].map(value => {
      const isEmpty = fields[value] !== null && fields[value].length > 0
      setError({ ...error, ...{ [value]: true } })
      return isEmpty
    })

    if (empties.indexOf(false) > -1) {
      setStatusMsg("One or more fields are missing!")
      isLoading(false)
    } else {
      sendRequest()
    }

  }

  return (
    <div className="contact-root">
                <div className="contact-header">
                    <h1 className="contact-title">{serviceSettings.title}</h1>
                    <p className="contact-instructions">{serviceSettings.text}</p>
                    <Link className="contact-link" to={serviceSettings.link}>{serviceSettings.linkText}</Link>
                </div>

                <div className="contact-formCont">
                <div className="contact-inputCont">
                    <label for="firstName">First Name *</label>
                    <input className="contact-input" id="firstName" name="firstName" placeholder="" onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
                </div>
                <div className="contact-inputCont">
                    <label for="lastName">Last Name *</label>
                    <input className="contact-input" id="lastName" name="lastName" placeholder="" onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
                </div>
                <div className="contact-inputCont service-email">
                    <label for="email">Email *</label>
                    <input className="contact-input" type="email" id="email" name="email" placeholder="" onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
                </div>
                <div className="contact-inputCont service-phone">
                    <label for="phoneNumber">Phone *</label>
                    <input className="contact-input" type="tel" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" id="phoneNumber" name="phoneNumber" placeholder="(555) 555-5555" 
                        onChange={(e) => handleTextInput(e.target.name, e.target.value)} 
                        onInput={(e) => phoneMask(e)}/>
                </div>

                <h3 className="contact-vehicle-header">Tell us about your vehicle</h3>
                <div className="contact-inputCont contact-year">
                    <label for="vehicleYear">Year</label>
                    <input className="contact-input" type="number" id="vehicleYear" name="vehicleYear" placeholder="" onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
                </div> 
                <div className="contact-inputCont">
                    <label for="vehicleMake">Make</label>
                    <input className="contact-input" id="vehicleMake" name="vehicleMake" placeholder="" onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
                </div>
                <div className="contact-inputCont contact-model">
                    <label for="vehicleModel">Model</label>
                    <input className="contact-input" id="vehicleModel" name="vehicleModel" placeholder="" onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
                </div>
                <div className="contact-inputCont">
                    <label for="vehicleTrim">Trim</label>
                    <input className="contact-input" id="vehicleTrim" name="vehicleTrim" 
                        onChange={(e) => handleTextInput(e.target.name, e.target.value)}/>
                </div>
                <div className="contact-inputCont contact-comment">
                    <label for="vehicleMiles">Mileage</label>
                    <input className="contact-input" id="vehicleMiles" name="vehicleMiles" 
                        onChange={(e) => handleTextInput(e.target.name, e.target.value)}/>
                </div>
                <div className="contact-inputCont contact-comment">
                    <label for="comments">Comment</label>
                    <input className="contact-input" id="comments" name="comments" placeholder="" onChange={(e) => handleTextInput(e.target.name, e.target.value)} />
                </div>

                <h3 className="contact-vehicle-header">When would you like to come in?</h3>
                <div className="contact-inputCont contact-comment">
                    <label for="serviceDate">Choose Date *</label>
                    <Datepicker selected={pickerDate} onChange={date => setPickerDate(date)}/>
                </div>

                <h3 className="contact-vehicle-header">How would you like us to contact you?</h3>
                <ul className="contact-method dropdown-sorting"  onMouseLeave={hideMenus}  onClick={() => toggleMenu(6)}>
                    <li className="dropdown-v1 srp-dropdown__model" onMouseLeave={hideMenus}  
                        onClick={() => {toggleMenu(6); handleTextInput('contactType', getStorageItem("contact:Type"))}} style={{height: "40px"}}>
                        <div className="sr-dropdown__inner">
                        <p className="sr-dropdown__name" style={{marginTop: "3px"}}>{getStorageItem("contact:Type") ?? "Any Way"}</p>
                        <input hidden id="contactType" name="contactType" value={getStorageItem("contact:Type")}
                            onChange={(e) => handleTextInput(e.target.name, e.target.value)}/>
                        </div>
                        {isOpen[6] &&
                        (<ul className="sub-dropdown-v1 search-dropdown" style={{top:"40px"}}>
                        <li className="sub-dropdown-v1__item"  onClick={() => { toggleMenu(6); setStorageItem("contact:Type", "Any Way"); }}>Any Way</li>
                        <li className="sub-dropdown-v1__item"  onClick={() => { toggleMenu(6); setStorageItem("contact:Type", "Phone"); }}>Phone</li>
                        <li className="sub-dropdown-v1__item"  onClick={() => { toggleMenu(6); setStorageItem("contact:Type", "Email"); }}>Email</li>
                        </ul>)}
                    </li>
                </ul>

                <div className="contact-inputCont contact-border">
                <p className="contact-disclaimer">{serviceSettings.disclaimer}</p>
                    <button className="contact-button" onClick={submitForm}>
                    {loading === false ? serviceSettings.buttonText :
                        <Loader type="ThreeDots" color="white" height={12} width={12} />
                    }
                    </button>
                </div>
                <div className={`contact-response ${statusMsg.length && "contact-failure"}`}>
                    {statusMsg}
                </div>
            </div>
        </div>
  )
}

ServiceForm.defaultProps = {
  leadURL: "",
  disclaimer: ""
}

export default ServiceForm